<template>
  <div :id="id" class="multiselect-custom">
    <label v-if="label.length" class="font-bold" for="">
      {{ label }}
    </label>
    <p v-if="sublabel.length" class="text-[14px]">
      {{ sublabel }}
    </p>
    <VueMultiselect
      v-model="modelValue"
      :name="name"
      :options="Array.from(options)"
      :placeholder="placeholder"
      :label="labelIdentifier"
      :custom-label="customLabel"
      :track-by="valueIdentifier"
      :disabled="disabled"
      :loading="isLoading"
      :searchable="searchable"
      :max-height="179"
      :select-label="t('multiselect.selectLabel')"
      :selected-label="t('multiselect.selectedLabel')"
      :deselect-label="t('multiselect.deselectLabel')"
      @search-change="handleSearchChanged"
      @select="handleValueChanged"
      @remove="handleRemovedOption"
    >
      <template v-if="$slots['singleLabel']" #singleLabel="{ option }">
        <slot name="singleLabel" v-bind="option">
          <span>{{ option.label }}</span>
        </slot>
      </template>
      <template v-if="$slots['option']" #option="optionProps">
        <slot name="option" v-bind="optionProps">
          <span v-if="optionProps.option.value !== tmpCustomOptionIdentifier">
            {{ optionProps.option.label }}
          </span>
          <span v-if="optionProps.option.value === tmpCustomOptionIdentifier">
            <FaIcon icon-class="fal fa-plus-circle" /> "{{
              optionProps.option.label
            }}"
            {{ t('multiselect.customAdd') }}
          </span>
        </slot>
      </template>
      <template #caret="{ toggle }">
        <div>
          <div class="multiselect__select" @mousedown.prevent.stop="toggle">
            <OpenIndicator />
          </div>
        </div>
      </template>
    </VueMultiselect>
  </div>
</template>

<script setup lang="ts">
import VueMultiselect from 'vue-multiselect';
import type { OptionType } from '@/helpers/disturbanceForm';
import FaIcon from '@/components/fa-icon.vue';
import OpenIndicator from '../components/OpenIndicator.vue';
import { useActiveElement } from '@vueuse/core';

const props = defineProps({
  initialOptions: {
    type: Array as PropType<OptionType[]>,
    required: true,
    default: () => [],
  },
  value: {
    type: [Object, null] as PropType<OptionType>,
    required: false,
    default: {} as OptionType,
  },
  id: {
    type: String,
    required: false,
    default: '',
  },
  label: {
    type: String,
    required: false,
    default: '',
  },
  placeholder: {
    type: String,
    required: false,
    default: 'Bitte auswählen',
  },
  isLoading: {
    type: Boolean,
    required: true,
    default: false,
  },
  isExtendable: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  maxlength: {
    type: Number,
    required: false,
    default: 255,
  },
  searchable: {
    type: Boolean,
    required: false,
    default: true,
  },
  sublabel: {
    type: String,
    required: false,
    default: '',
  },
  name: {
    type: String,
    required: false,
    default: '',
  },
  labelIdentifier: {
    type: String,
    default: 'label',
  },
  valueIdentifier: {
    type: String,
    default: 'value',
  },
  selectedOptionLabelIdentifier: {
    type: String,
    default: null,
  },
});

const emit = defineEmits<{
  (e: 'onValueChanged', value: OptionType): void;
}>();

const { t } = useTrans();
const tmpCustomOptionIdentifier = 'tmpCustomOption';
const customOptionIdentifier = 'customOption';
const options = computed(() => props.initialOptions);
const modelValue = defineModel<object | string | null>();
const activeSearchQuery = ref();
const activeSearchElement = useActiveElement();

function handleSearchChanged(searchQuery: string) {
  activeSearchQuery.value = searchQuery;

  if (!props.isExtendable) {
    return;
  }

  const tmpCustomOption = options.value.filter(
    (o) => o.value === tmpCustomOptionIdentifier,
  );

  // If all chars are removed from search, we have to remove custom answer from options
  if (!searchQuery.length) {
    if (tmpCustomOption.length) {
      options.value.filter(removeTmpCustomIdentifier);
    }
    return;
  }

  const optionWithValueAlreadyExists =
    options.value.filter(
      (o) => o.label.toLowerCase() === searchQuery.toLowerCase(),
    ).length > 0;

  if (optionWithValueAlreadyExists) {
    return;
  }

  if (!tmpCustomOption.length) {
    options.value.push({ label: '', value: tmpCustomOptionIdentifier });
  }

  options.value.map((o) => {
    if (o.value === tmpCustomOptionIdentifier) {
      o.label = searchQuery;
    }
  });
}

function handleValueChanged(selectedOption: OptionType) {
  emit('onValueChanged', selectedOption);

  if (!props.isExtendable) {
    return;
  }

  if (selectedOption.value === tmpCustomOptionIdentifier) {
    options.value.filter(removeCustomIdentifier);
    options.value.map((o) => {
      if (o.value === tmpCustomOptionIdentifier) {
        o.value = customOptionIdentifier;
        return o;
      }
    });
  }

  options.value.filter(removeTmpCustomIdentifier);
}

function removeTmpCustomIdentifier(
  object: OptionType,
  index: number,
  arr: OptionType[],
) {
  if (object.value === tmpCustomOptionIdentifier) {
    arr.splice(index, 1);
    return true;
  }

  return false;
}

function removeCustomIdentifier(
  object: OptionType,
  index: number,
  arr: OptionType[],
) {
  if (object.value === customOptionIdentifier) {
    arr.splice(index, 1);
    return true;
  }

  return false;
}

function customLabel(option: any) {
  return `${option[props.labelIdentifier]}`;
}

function handleRemovedOption() {
  emit('onValueChanged', null);
}

watch(activeSearchQuery, () => {
  (activeSearchElement.value as HTMLInputElement).setAttribute(
    'maxLength',
    props.maxlength.toString(),
  );
});
</script>
<style lang="postcss" scoped>
.multiselect-custom :deep(.multiselect) {
  min-height: 36px;
}
.multiselect-custom :deep(.multiselect--active) {
  .multiselect__tags {
    @apply border-b-primary-base;
  }
}

.multiselect-custom :deep(.multiselect__tags) {
  @apply bg-white border-border-light border border-b-[3px] h-9 px-2 md:px-4 py-[9px] text-md text-text-base ring-0 rounded-alt-xs !pr-[40px];
  min-height: 36px;
  height: 36px;
  padding-top: 0;
  padding-bottom: 0 !important;

  input {
    padding: 0 !important;
  }
}

.multiselect-custom :deep(.multiselect__input) {
  padding-top: 1px;
  height: 32px;
}

.multiselect-custom :deep(.multiselect__placeholder) {
  //padding-top: 7px;
}

.multiselect-custom :deep(.multiselect__single) {
  //padding-top: 7px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px !important;
  height: 100%;
}

.multiselect-custom :deep(.multiselect__option--highlight) {
  color: var(--thm-text-dark);
  background: var(--thm-primary-lighter);
}
.multiselect-custom :deep(.multiselect__option--highlight:after) {
  color: var(--thm-text-base);
  background: var(--thm-primary-lighter);
}

:deep(.multiselect__option--selected) {
  color: var(--thm-white-base);
  background: var(--thm-primary-base);
}
:deep(.multiselect__option--selected:after) {
  color: var(--thm-white-base);
  background: var(--thm-primary-base);
}

:deep(
    li.multiselect__element:hover
      .multiselect__option--highlight.multiselect__option--selected
  ) {
  color: var(--thm-white-base);
  background: var(--thm-status-danger-base);
}
:deep(
    li.multiselect__element:hover
      .multiselect__option--highlight.multiselect__option--selected:after
  ) {
  color: var(--thm-white-base);
  background: var(--thm-status-danger-base);
}

.multiselect-custom :deep(.multiselect__spinner) {
  height: 34px;
}

.multiselect-custom :deep(.multiselect__spinner:before) {
  border-color: #346e73 transparent transparent;
}

.multiselect-custom :deep(.multiselect__spinner:after) {
  border-color: #346e73 transparent transparent;
}

.multiselect-custom :deep(.multiselect__select) {
  height: 32px;
  width: 34px;
  padding: 8px 8px !important;
  right: 12px !important;
}
.multiselect-custom :deep(.multiselect__select:before) {
  content: none;
}

.multiselect-custom :deep(.multiselect__select:after) {
  content: none;
}

.multiselect--disabled :deep(.multiselect__select) {
  background: unset;
}

.multiselect-custom :deep(.multiselect__content-wrapper) {
  max-height: 365px !important;
  border: none !important;
  box-shadow: 0px 3px 6px #00000029;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
