import {
  useSecureSessionDelete,
  useSecureSessionPost,
  useSessionGet,
} from '@/composables/dataFetching/genericFetchers';
import type { Result } from '@/server/api/[site]/user/verficationFile/all.get';
import { useUserContext } from '~/stores/useUserContext';
import useDeleteVerificationFileDialog from '~/composables/dialogs/useDeleteVerificationFileDialog';
import useUploadVerificationFileDialog from '@/composables/dialogs/useUploadVerificationFileDialog';
import type { CustomerGroupId } from '~/@types/customerGroupIds';
import useCustomerGroupMaps from '~/composables/user/useCustomerGroupMaps';
import { handleLoadingError } from '~/utils/handleLoadingError';

export default function useVerficationFiles() {
  const siteIdent = useSiteIdent();

  async function needsVerificationFiles() {
    const userContext = useUserContext();
    await userContext.loadAccountData();
    return useCustomerGroupMaps().verificationNeeded[siteIdent].includes(
      userContext.accountData.customerType.id as CustomerGroupId,
    );
  }

  async function needsDrugVerificationFiles() {
    const userContext = useUserContext();
    await userContext.loadAccountData();
    return useCustomerGroupMaps().drugPurchasePossible[siteIdent].includes(
      userContext.accountData.customerType.id as CustomerGroupId,
    );
  }

  async function downloadFiles(id: string) {
    try {
      await useSessionGet(
        `/api/${siteIdent}/user/verficationFile/${id}/download`,
        {
          responseType: 'arrayBuffer',
          onResponse: ({ response }) => {
            if (!response?._data) return;
            const blob = new Blob([response._data], {
              type: response.headers.get('content-type'),
            });
            const url = URL.createObjectURL(blob);
            window.open(url);
            URL.revokeObjectURL(url);
          },
        },
      );
    } catch (e) {
      handleLoadingError(e);
    }
  }

  async function loadFiles() {
    try {
      return (
        (await useSessionGet<Result>(
          `/api/${siteIdent}/user/verficationFile/all`,
        )) ?? []
      );
    } catch (e) {
      handleLoadingError(e);
      return [];
    }
  }

  async function deleteFile(id: string) {
    try {
      return await useSecureSessionDelete(
        `/api/${siteIdent}/user/verficationFile/${id}`,
      );
    } catch (e) {
      handleLoadingError(e);
    }
  }

  async function uploadFiles(body: FormData) {
    try {
      const result = await useSecureSessionPost<Result>(
        `/api/${siteIdent}/user/verficationFile/upload`,
        body,
      );
      if (!result?.length) return [];
      return result;
    } catch (e) {
      handleLoadingError(e);
    }
  }

  return {
    needsVerificationFiles,
    needsDrugVerificationFiles,
    loadFiles,
    downloadFiles,
    deleteFile,
    uploadFiles,
    useUploadDialog: useUploadVerificationFileDialog,
    useDeleteDialog: useDeleteVerificationFileDialog,
  };
}
