<template>
  <FormKit
    :id="id"
    :value="value"
    :type="select"
    :name="name ?? id"
    :initial-options="initialOptions"
    :input-container-style="'relative'"
    :outer-class="'mb-sm'"
    :label-identifier="labelIdentifier"
    :value-identifier="valueIdentifier"
    :selected-option-label-identifier="selectedOptionLabelIdentifier"
    input-class="input"
    :inner-class="{
      'pt-[5px]': true,
      relative: true,
      'opacity-50 ': disabled && !hasWhiteBg,
      'opacity-50': disabled && hasWhiteBg,
    }"
    validation-visibility="blur"
    :validation="required ? 'required' : ''"
    :disabled="disabled"
    :maxlength="maxlength"
    :is-loading="isLoading"
    :searchable="searchable"
    :placeholder="placeholder ? t(placeholder) : undefined"
    @input="$emit('input', $event)"
  >
    <template #label>
      <FormLabel :label="label" :required="required" />
    </template>
  </FormKit>
</template>
<script setup lang="ts">
import { createInput, FormKit } from '@formkit/vue';
import customSelect from '~~/src/components/formFields/components/customSelects/multiSelect/customMultiSelect.vue';
import { FormLabel } from '@/complib';

defineEmits<{
  (e: 'input', event: Event): string;
}>();

const props = defineProps({
  options: {
    type: Array as PropType<Array<any>>,
    required: true,
  },
  id: {
    type: String,
    required: false,
    default: '',
  },

  name: {
    type: String,
    required: false,
    default: '',
  },
  label: {
    type: String,
    required: true,
  },
  required: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  maxlength: {
    type: Number,
    default: 255,
  },
  initialValue: {
    type: String,
    default: '',
  },
  hasWhiteBg: {
    type: Boolean,
    default: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  searchable: {
    type: Boolean,
    default: true,
  },
  placeholder: {
    type: String,
    default: undefined,
  },
  labelIdentifier: {
    type: String,
    default: 'label',
  },
  valueIdentifier: {
    type: String,
    default: 'value',
  },
  selectedOptionLabelIdentifier: {
    type: String,
    default: null,
  },
});

const { t } = useTrans();

const value = ref<Record<string, string>>(
  getSelectedOption(props.initialValue),
);
const initialOptions = ref<Array<Record<string, string>>>(props.options);
if (!value.value && props.initialValue) {
  const customOption = {
    [props.labelIdentifier]: props.initialValue,
    [props.valueIdentifier]: 'customOption',
  };
  initialOptions.value = [...props.options, customOption];
  value.value = customOption;
}

function getSelectedOption(value: string) {
  const option = props.options.find((option) => option?.value === value);
  if (option) return option;
  else if (value === 'customOption') {
    return {
      [props.labelIdentifier]: value,
      [props.valueIdentifier]: 'customOption',
    };
  }
  return null;
}

const select = createInput(customSelect, {
  props: [
    'initialOptions',
    'label',
    'placeholder',
    'value',
    'id',
    'disabled',
    'maxlength',
    'sublabel',
    'searchable',
    'labelIdentifier',
    'valueIdentifier',
    'selectedOptionLabelIdentifier',
  ],
});
</script>
