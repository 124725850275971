<template>
  <FormSelectMultiselect
    :id="context.id"
    v-model="modelValue"
    :name="context.inputName"
    :placeholder="context.placeholder"
    :disabled="context.disabled"
    :maxlength="context.maxlength"
    :initial-options="context.initialOptions"
    :is-loading="context.isLoading"
    :label-identifier="context.labelIdentifier"
    :value-identifier="context.valueIdentifier"
    :selected-option-label-identifier="context.selectedOptionLabelIdentifier"
    :sublabel="context.sublabel"
    :searchable="context.searchable"
    is-extendable
  >
    <template v-if="$slots['singleLabel']" #singleLabel="optionProps">
      <slot name="singleLabel" v-bind="optionProps" />
    </template>
    <template v-if="$slots['option']" #option="optionProps">
      <slot name="option" v-bind="optionProps" />
    </template>
  </FormSelectMultiselect>
</template>

<script setup lang="ts">
import FormSelectMultiselect from './multiSelect.vue';
import { toRef } from 'vue';

const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  context: Object,
});

const modelValue = defineModel<object | string | null>('selected');
const contextRef = toRef(props, 'context');

watch(
  () => props.context._value,
  () => handleChange(),
);

onMounted(() => {
  handleChange();
});

watch(
  () => modelValue.value,
  (nV) => {
    props.context.node.input(nV);
  },
);

const handleChange = () => {
  if (!contextRef.value._value) {
    if (modelValue.value) {
      modelValue.value = null;
      return;
    }
  }

  modelValue.value = contextRef.value._value;
};
</script>
