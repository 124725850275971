import { ErrorIdent } from '@/@types/errorIdents';
import { useUserContext } from '@/stores/useUserContext';
import type {
  PostBody as CreateBody,
  PostBody as EditBody,
} from '@/server/api/[site]/user/account/newAddress.post';
import { AddressType, useUserAddress } from '@/stores/useUserAddress';
import useNewEditAddressDialog from '@/composables/dialogs/useNewEditAddressDialog';
import useVerificationFiles from '@/composables/user/useVerficationFiles';
import { SiteIdent } from '@/@types/siteIdent';
import useValidateAddressExternal from '~/components/formTools/address/validation/composables/useValidateAddressExternal';
import { getNode } from '@formkit/core';

interface NewEditAddressForm {
  name1: string;
  name2: { value: string; label: string };
  streetNumber: {
    street: string;
    houseNumber: string;
  };
  zipCity: {
    zip: string;
    city: string;
  };
  country: string;
  defaultBillingAddress: boolean;
  defaultShippingAddress: boolean;
}

export async function useNewEditAddress() {
  const newEditAddressDialog = useNewEditAddressDialog();
  const useValidateAddress = useValidateAddressExternal();
  const { t } = useTrans();

  const userContext = useUserContext();
  const userAddress = useUserAddress();
  const errors = ref<ErrorIdent[]>([]);
  const isLoading = ref(false);
  const canHaveDrugs =
    await useVerificationFiles().needsDrugVerificationFiles();
  const data = await userContext.loadAccountData();
  const site = useSiteIdent();

  const countryOptions = userAddress.countries.map((country) => ({
    label: country.name,
    value: country.code,
  }));

  const label = data.customerType.label;
  const name1RequiredErrorMsg = t(
    'registration.emptyError.billingAddress.name1',
    {
      name: data.customerType.errorName,
    },
  );

  const name2Suggestions = data.name2Suggestions.map((name) => ({
    label: t(name),
    value: t(name),
  }));
  const dialogData = newEditAddressDialog.getData();

  // Reactive statt refs für mehr Übersicht wären besser
  const selectedCountry = ref();
  const name1 = ref();
  const name2 = ref();
  const street = ref();
  const houseNumber = ref();
  const zip = ref();
  const city = ref();
  const isDefaultBillingAddress = ref(false);
  const isDefaultShippingAddress = ref(false);
  const defaultBillingDisabled = ref(false);
  const defaultShippingDisabled = ref(false);

  if (dialogData?.address) {
    if (!data.canEdit && dialogData.address.isContactAddress)
      newEditAddressDialog.setHeadline(
        t('user.account.addresses.newEditDialog.newHeadline'),
      );
    else
      newEditAddressDialog.setHeadline(
        t('user.account.addresses.newEditDialog.editHeadline'),
      );
    name1.value = dialogData.address.name1;
    name2.value = dialogData.address.name2;
    street.value = dialogData.address.street;
    houseNumber.value = dialogData.address.number;
    zip.value = dialogData.address.zip;
    city.value = dialogData.address.city;
    selectedCountry.value = countryOptions.find(
      ({ label }) => label === dialogData.address.country,
    )?.value;
    isDefaultBillingAddress.value = dialogData.address.isDefaultBillingAddress;
    isDefaultShippingAddress.value =
      dialogData.address.isDefaultShippingAddress;
    defaultBillingDisabled.value = dialogData.address.isDefaultBillingAddress;
    defaultShippingDisabled.value = dialogData.address.isDefaultShippingAddress;
  } else {
    newEditAddressDialog.setHeadline(
      t('user.account.addresses.newEditDialog.newHeadline'),
    );
    name2.value = data.customerType.name;
    selectedCountry.value = countryOptions[0].value;
  }

  async function onSubmit(formData: NewEditAddressForm) {
    isLoading.value = true;

    try {
      await useValidateAddress.validateAddress({
        countryId: userAddress.countries.find(
          (country) => country.code === formData.country,
        )?.code,
        city: formData.zipCity.city,
        zipcode: formData.zipCity.zip,
        street: formData.streetNumber.street,
        number: formData.streetNumber.houseNumber,
      });
    } catch (e) {
      handleLoadingError(e);
      // We just keep going and ignore the validation if it fails  (for any reason)
    }

    if (useValidateAddress.needsManualCheck.value) {
      isLoading.value = false;
      return;
    }

    await createEditAddress(formData);
    isLoading.value = false;
  }

  async function createEditAddress(formData: NewEditAddressForm) {
    try {
      isLoading.value = true;
      const postData: CreateBody & EditBody = {
        name1: formData.name1,
        name2: formData.name2?.label,
        street: formData.streetNumber.street,
        houseNumber: formData.streetNumber.houseNumber,
        zip: formData.zipCity.zip,
        city: formData.zipCity.city,
        countryId: userAddress.countries.find(
          (country) => country.code === formData.country,
        )?.id,
        isDefaultBillingAddress: formData.defaultBillingAddress,
        isDefaultShippingAddress: formData.defaultShippingAddress,
      };
      let result = null;
      if (
        !dialogData?.address ||
        (dialogData?.address &&
          !data.canEdit &&
          dialogData.address.isContactAddress)
      ) {
        result = await userAddress.createAddress(
          dialogData?.addressType ?? AddressType.INDIFFERENT,
          postData,
          {
            autoChangeActiveAddress: true,
          },
        );
      } else {
        result = await userAddress.editAddress(
          dialogData.addressType,
          postData,
          dialogData.address.id,
          { autoChangeActiveAddress: true },
        );
      }
      if (result) {
        newEditAddressDialog.close();
      }
      isLoading.value = false;
    } catch (e: any) {
      if (e?.data?.data?.errors) {
        errors.value = e?.data?.data?.errors;
      } else {
        errors.value = [ErrorIdent.UNKNOWN_ERROR];
        // eslint-disable-next-line no-console
        console.error(e);
      }
      document.querySelector('.js-error-container')?.scrollIntoView({
        behavior: 'smooth',
      });
      isLoading.value = false;
    } finally {
      await userAddress.loadAddresses(true);
    }
  }

  const showAddressDrugHint = computed(() => {
    return (
      canHaveDrugs &&
      isDefaultShippingAddress.value === true &&
      (!dialogData?.address?.isContactAddress || !data.canEdit) &&
      [SiteIdent.default, SiteIdent.miniluDe].includes(site)
    );
  });

  function handleFillAddress(data: any) {
    useValidateAddress.resetManualCheck();
    getNode('registrationStreet').input(data.street);
    getNode('registrationHouseNumber').input(data.number);
    getNode('registrationCity').input(data.city);
    getNode('registrationZip').input(data.zipcode);
  }

  return {
    onSubmit,
    errors,
    data,
    isLoading,
    countryOptions,
    selectedCountry,
    label,
    name1RequiredErrorMsg,
    name1,
    name2,
    street,
    houseNumber,
    zip,
    city,
    isDefaultBillingAddress,
    isDefaultShippingAddress,
    defaultBillingDisabled,
    defaultShippingDisabled,
    name2Suggestions,
    showAddressDrugHint,
    needsManualCheck: useValidateAddress.needsManualCheck,
    resetManualCheck: useValidateAddress.resetManualCheck,
    validationResult: useValidateAddress.validationResult,
    handleFillAddress,
  };
}
